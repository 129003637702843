<h1 matDialogTitle>Feedback</h1>
<app-loader [isActive]="this.isLoading$ | async" [enableCircle]="true" [width]="24"></app-loader>
<mat-dialog-content cdkTrapFocus>
  <form action="" (submit)="$event.preventDefault()" [formGroup]="this.group" class="my-0">
    <div tg-form-field>
      <label tg-form-field-label for="subject"></label>
      <div tg-container>
        <input
          type="text"
          formControlName="subject"
          id="subject"
          tg-input
          cdkInitialFocus
          [placeholder]="'feedback.subject' | translate"
        />
      </div>
      <div class="text-right text-sm mat-hint mt-1" *ngIf="this.group?.controls?.subject?.errors?.minlength as subj">
        {{ subj.actualLength }} / {{ subj.requiredLength }}
      </div>
    </div>
    <div tg-form-field>
      <label tg-form-field-label for="type"></label>
      <div tg-container>
        <mat-select type="text" formControlName="type" class="px-2 py-2 tg-input tg-input-appearance-normal">
          <mat-option *ngFor="let ftype of feedbackTypesArr" [value]="feedbackTypes[ftype.key]">
            {{ 'feedback-type.' + ftype.value | translate }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div tg-form-field>
      <label tg-form-field-label for="name"></label>
      <div tg-container>
        <input type="text" formControlName="name" id="name" tg-input [placeholder]="'feedback.name' | translate" />
      </div>
    </div>
    <div tg-form-field>
      <label tg-form-field-label for="email"></label>
      <div tg-container>
        <input type="text" formControlName="email" id="email" tg-input placeholder="'feedback.mail' | translate" />
      </div>
    </div>
    <div tg-form-field>
      <label tg-form-field-label for="description"></label>
      <div tg-container>
        <textarea
          type="text"
          formControlName="description"
          id="description"
          tg-input
          [placeholder]="'feedback.description' | translate"
          rows="5"
        ></textarea>
      </div>
      <div
        class="text-right text-sm mat-hint mt-1"
        *ngIf="this.group?.controls?.description?.errors?.minlength as descInputLength"
      >
        {{ descInputLength.actualLength }} / {{ descInputLength.requiredLength }}
      </div>
    </div>
    <div class="tg-form-field" *ngIf="this.group?.value?.type === feedbackTypes.ACCOUNT_DELETION">
      <mat-checkbox formControlName="newsletter_subscription" color="primary">
        <small class="text-wrap">{{ 'feedback.deletion-newsletter' | translate }}</small>
      </mat-checkbox>
    </div>
  </form>

  <div
    class="alert alert-primary dialog-alert-filled"
    *ngIf="this.group?.value?.type === feedbackTypes.ACCOUNT_DELETION"
  >
    <small>
      {{ 'feedback.deletion-feedback-alert' | translate }}
    </small>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    color="primary"
    (click)="this.submit()"
    [disabled]="!this.group?.valid || (this.isLoading$ | async)"
    class="mat-button-filled"
  >
    {{ 'utils.submit' | translate }}
  </button>
</mat-dialog-actions>
